'use strict';

function reactStart_2_0(container, content, value) {
	ReactDOM.render(
		(<UxrMain20 />),
		container[0]
	);
}

/*---------------------------------------------------------------------*/
function UxrRenderDuoLangLabel(deu, eng) {
	return (
		<span>
			<span>{deu}</span>
			<span dangerouslySetInnerHTML={{__html:"&#x29F8;"}} />
			<span style={{color:"darkgray"}}>{eng}</span>
		</span>
	);
}

/*******************************************************************************************/
class UxrMain20 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onClick_iconCenter = this.onClick_iconCenter.bind(this);
		this.onClick_interactNow = this.onClick_interactNow.bind(this);

		GLOBALS.mainReact = this;
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let o = this;
		
		let popupContainers = {};
		
		popupContainers['_search'] = (<UxrPopupSearch20 key="_search" />);
		popupContainers['_cluster'] = (<UxrPopupCluster20 key="_cluster" />);
		popupContainers['_networkList'] = (<UxrPopupNetworkList20 key="_networkList" />);
		
		_.forEach(GLOBALS.popupsData, function(each, id) {
			switch (each.type) {
				case "v1_ereignis":
					popupContainers[id] = (<UxrPopupContent20Event popupId={id} key={id} />);
					break;
				case "v1_netzwerk":
					popupContainers[id] = (<UxrPopupContent20Network popupId={id} key={id} />);
					break;
				default:
					throw each.type;
			}
		});
		
		popupContainers = _.values(popupContainers);
		
		return (
			<div ref={this.onJQueryMount} className="mapInactive" >
				<div id="overlay" className="fullScreenCover">
					<a href="/intro"><div className="overlayButton">Über das Projekt</div></a>
					<a href="/en/intro"><div className="overlayButton">About the project</div></a>
					<br />
					<div className="overlayButton" onClick={this.onClick_interactNow}>Interaktive Karte</div>
					<div className="overlayButton" onClick={this.onClick_interactNow}>Interactive map</div>
				</div>
				<div id="sidebar">
					<div className="sidebarIcon iconSearch" title="Suche / search" onClick={GLOBALS.stateEngine.searchToggle}><div className="fa fa-search"></div></div>
					<div className="sidebarIcon iconNetwork" title="Netzwerk-Einträge anzeigen / show network entries" onClick={GLOBALS.stateEngine.listofnetworksToggle}><div className="">{ svgMarker_netzwerk_inner() }</div></div>
					<div className="sidebarIcon iconCenter" title="Karte zentrieren / center map" onClick={this.onClick_iconCenter}><div className="fa fa-compass"></div></div>
				</div>
				<div id="popups">{popupContainers}</div>
				<div id="map"></div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		
		GLOBALS.e = new ELEMENT_2_0();
		GLOBALS.map = new MAP_2_0(jQuery('#map'), GLOBALS.clusters);
		
		if (window.GET_SUB) {
			var type = window.GET_SUB[0];
			var value = decodeURIComponent(window.GET_SUB[1]);
			switch (type) {
				case '!street':
					var cluster = GLOBALS.clusters[value];
					GLOBALS.stateEngine.markerShow(cluster);
					this.onClick_interactNow();
					break;
				case '!entry':
					var id = parseInt(value);
					GLOBALS.stateEngine.contentShow(id);
					this.onClick_interactNow();
					break;
				case '!networks':
					var id = parseInt(value);
					GLOBALS.stateEngine.listofnetworksToggle();
					this.onClick_interactNow();
					break;
				case '!search':
					var id = parseInt(value);
					GLOBALS.stateEngine.searchToggle();
					this.onClick_interactNow();
					break;
			}
		}
		
		WINDOW.on('resize orientationchange', respaceScene);
		respaceScene();
	}
	
	/*---------------------------------------------------------------------*/
	onClick_interactNow() {
		this.jQ.addClass("mapInteractive").removeClass("mapInactive");
	}
	
	/*---------------------------------------------------------------------*/
	onClick_iconCenter() {
		GLOBALS.stateEngine.centerMap();
	}
	
	/*---------------------------------------------------------------------*/
	onClick_listNetworks() {
		//TODO
	}
	
} //class

/*******************************************************************************************/
function respaceScene() {
	for (var i=0; i<GLOBALS.spaceObjects.length; i++) {
		var eachSO = GLOBALS.spaceObjects[i];
		eachSO.computeSpace().applySpace();
	}
	
	GLOBALS.stateEngine.centerMap();
	// if (GLOBALS.map) {
	// 	if (GLOBALS.focusMarker === null) {
	// 		GLOBALS.map.containAll(GLOBALS.activeClusters);
	// 	} else {
	// 		GLOBALS.map.centerOn(GLOBALS.focusMarker);
	// 	}
	// }
} //event

